import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import countries from "../data/countires.json";
import { useRouter } from "next/router";
import SearchIcon from "@mui/icons-material/Search";

const MobileSearch = () => {
  const [value, setValue] = useState({});
  const router = useRouter();

  const handleInputChange = (event, value) => {
    setValue(value);
    const { query } = router;

    query["destination"] = value.toString();

    router.push({ pathname: `/search`, query: query });
  };

  return (
    <>
      <Autocomplete
        sx={{
          display: "flex",
          alignItems: "center",
          width: 320,
        }}
        autoHighlight
        options={countries}
        getOptionLabel={(option) => option.label}
        onInputChange={handleInputChange}
        renderOption={(props, option) => (
          <Box
            component={"li"}
            sx={{
              "& > img": { mr: 2, flexShrink: 0 },
            }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            />
            {option.label} ({option.code})
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            placeholder="Search destination..."
            label="Where to?"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px",
                maxHeight: "50px",
                boxShadow: "0 3px 10px rgb(0 0 0 / 10%)",
              },
            }}
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <span className="contract-search-icon">
                  <SearchIcon/>
                </span>
              ),
            }}
            // {...params}
            // inputProps={{
            //   ...params.inputProps,
            // }}
          />
        )}
      />
    </>
  );
};

export default MobileSearch;
